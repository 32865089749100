.guide-page {
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
  padding-top: 74px;
}

.guide-header {
  text-align: center;
  margin-bottom: 40px;
}

.guide-header h1 {
  font-size: 2em;
  color: #333;
  margin-bottom: 15px;
}

.guide-intro {
  color: #666;
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
}

.guide-content {
  max-width: 800px;
  margin: 0 auto;
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.guide-section {
  margin-bottom: 40px;
}

.guide-section h2 {
  font-size: 1.5em;
  color: #333;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #f0f0f0;
}

.guide-section h3 {
  font-size: 1.3em;
  color: #444;
  margin-bottom: 15px;
}

.guide-section ul,
.guide-section ol {
  list-style: none;
  padding-left: 0;
}

.guide-section li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 15px;
  color: #444;
  line-height: 1.8;
  text-align: center;
}

.guide-section li::before {
  content: "•";
  position: absolute;
  left: 8px;
  color: #666;
}

.guide-section ol li {
  text-align: left;
  padding-left: 40px;
}

.guide-section ol li::before {
  content: counter(item) ".";
  counter-increment: item;
  left: 20px;
}

.back-button {
  display: inline-block;
  text-decoration: none;
  color: #333;
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f8f9fa;
  margin-top: 20px;
  transition: all 0.3s ease;
}

.back-button:hover {
  background-color: #e9ecef;
  border-color: #ccc;
}

@media (max-width: 800px) {
  .guide-content {
    padding: 20px;
  }
  
  .guide-header h1 {
    font-size: 1.8em;
  }
} 